import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const NotificationCard = ({ notification }) => {
  const { id, title, body, created_at, user } = notification;
  return (
    <>
      <div className="bg-white max-w-full mx-auto px-18 py-9 rounded-xl shadow-xl">
        <div className="font-semibold mb-2">{notification.title}</div>
        <div className="mb-2">
          {moment(notification.created_at).format("DD.MM.YYYY")}
        </div>
        <div>{notification.body}</div>
      </div>
    </>
  );
};

export default NotificationCard;
