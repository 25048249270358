import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import NotificationCard from "../components/Notifications/NotificationCard";
import withAuthentication from "../utils/withAuthentication";

import { listNotifications } from "../middleware/actions/notification";
import { getNotifications } from "../middleware/selectors/notification";

const Notifications = ({
  listNotifications,
  getNotifications,
  notificationList,
}) => {
  const { isFetching, isFetched, listData, total } = notificationList;

  const {
    listData: notificationListData,
    isFetching: notificationListFetching,
    isFetched: notificationListFetched,
  } = notificationList;

  useEffect(() => {
    listNotifications({ _sort: "updated_at:DESC" });
  }, []);

  return (
    <>
      <Header />
      <div className="container mx-auto mb-10">
        {notificationList.listData.map((item) => {
          return (
            <>
              <div className="mt-3 mr-3 ml-3">
                <NotificationCard notification={item}></NotificationCard>
              </div>
            </>
          );
        })}
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  notificationList: getNotifications(state),
});

const mapDispatchToProps = {
  listNotifications,
};

export default withAuthentication(connect(mapStateToProps, mapDispatchToProps)(Notifications));
