/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import ArrowDown from "../img/dropdown_white.svg";
import { logout } from "../middleware/actions/user";
import { useIntl } from "react-intl";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Dropdown = ({ logout }) => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const signout = async () => {
    console.log("log out");
    await logout();
    history.push("/home");
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="p-2 focus:outline-none focus:ring-0">
              <img src={ArrowDown} className="w-4" width="16" height="auto" />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/editProfile"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      {formatMessage({ id: "editProfile" })}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link to="/home">
                      <button
                        onClick={signout}
                        type="submit"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full text-left px-4 py-2 text-sm"
                        )}
                      >
                        {formatMessage({ id: "logOut" })}
                      </button>
                    </Link>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  logout,
};

export default connect(null, mapDispatchToProps)(Dropdown);

