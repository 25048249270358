import { Field } from "formik";

import FieldLabel from "../../atoms/FieldLabel";
import { useIntl } from "react-intl";

const DropdownField = ({ fieldName, fieldLabel, isRequired, options }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
        <FieldLabel label={fieldLabel} isRequired={isRequired} />
        <div className="inline-flex items-center justify-end">
          <Field
            as="select"
            name={fieldName}
            className="w-auto text-lg md:text-xl text-lightGray3 bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3 py-0"
            placeholder="..."
          >
            <option value=""></option>
            {options.values.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </Field>
        </div>
      </label>
    </>
  );
};

export default DropdownField;
