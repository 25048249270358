import getAxiosInstance from '../config/http';

export const LIST_SUBVENTION_REQUEST = 'SUBVENTION@LIST_SUBVENTION_REQUEST';
export const LIST_SUBVENTION_SUCCESS = 'SUBVENTION@LIST_SUBVENTION_SUCCESS';
export const LIST_SUBVENTION_FAILURE = 'SUBVENTION@LIST_SUBVENTION_FAILURE';

export const listSubventions = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_SUBVENTION_REQUEST,
  });
  try{
    const subventionData = await http.get('/subventions',{params});
    dispatch({
      type: LIST_SUBVENTION_SUCCESS,
      payload: {
        listData: subventionData.data,
      },
    });
  }catch(e){
    dispatch({
      type: LIST_SUBVENTION_FAILURE,
    });
  }
};

export const COUNT_SUBVENTION_REQUEST = 'SUBVENTION@COUNT_SUBVENTION_REQUEST';
export const COUNT_SUBVENTION_SUCCESS = 'SUBVENTION@COUNT_SUBVENTION_SUCCESS';
export const COUNT_SUBVENTION_FAILURE = 'SUBVENTION@COUNT_SUBVENTION_FAILURE';

export const countSubventions = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: COUNT_SUBVENTION_REQUEST,
  });
  try{
    const subventionData = await http.get('/subventions/count',{params});
    dispatch({
      type: COUNT_SUBVENTION_SUCCESS,
      payload: {
        listData: subventionData.data,
      },
    });
    return subventionData.data;
  }catch(e){
    dispatch({
      type: COUNT_SUBVENTION_FAILURE,
    });
  }
};

export const CREATE_SUBVENTION_REQUEST = 'SUBVENTION@CREATE_SUBVENTION_REQUEST';
export const CREATE_SUBVENTION_SUCCESS = 'SUBVENTION@CREATE_SUBVENTION_SUCCESS';
export const CREATE_SUBVENTION_FAILURE = 'SUBVENTION@CREATE_SUBVENTION_FAILURE';

export const createSubvention = (file, subventionData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_SUBVENTION_REQUEST,
  });
  try {
    const formData = new FormData();
    formData.append("files.featuredImage", file);
    formData.append(
      "data",
      JSON.stringify({ ...subventionData, published_at: null })
    );
    const { data } = await http.post("/subventions", formData);

    dispatch({
      type: CREATE_SUBVENTION_SUCCESS,
      payload: {
        subventionData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_SUBVENTION_FAILURE,
    });
    throw e;
  }
};

export const FETCH_SUBVENTION_REQUEST = "SUBVENTION@FETCH_SUBVENTION_REQUEST";
export const FETCH_SUBVENTION_SUCCESS = "SUBVENTION@FETCH_SUBVENTION_SUCCESS";
export const FETCH_SUBVENTION_FAILURE = "SUBVENTION@FETCH_SUBVENTION_FAILURE";

export const fetchSubvention = (subventionId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_SUBVENTION_REQUEST,
  });
  try {
    const subventionData = await http.get(`/subventions/${subventionId}`);
    dispatch({
      type: FETCH_SUBVENTION_SUCCESS,
      payload: {
        subventionData: subventionData.data,
      },
    });
    return subventionData.data;
  } catch (e) {
    dispatch({
      type: FETCH_SUBVENTION_FAILURE,
    });
  }
};

export const fetchSubventionAdmin = (subventionId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_SUBVENTION_REQUEST,
  });
  try {
    const subventionData = await http.get(`/subventions-admin/${subventionId}`);
    dispatch({
      type: FETCH_SUBVENTION_SUCCESS,
      payload: {
        subventionData: subventionData.data,
      },
    });
    return subventionData.data;
  } catch (e) {
    dispatch({
      type: FETCH_SUBVENTION_FAILURE,
    });
  }
};

export const UPDATE_SUBVENTION_REQUEST = "SUBVENTION@UPDATE_SUBVENTION_REQUEST";
export const UPDATE_SUBVENTION_SUCCESS = "SUBVENTION@UPDATE_SUBVENTION_SUCCESS";
export const UPDATE_SUBVENTION_FAILURE = "SUBVENTION@UPDATE_SUBVENTION_FAILURE";

export const updateSubvention =
  (id, file, subventionData) => async (dispatch) => {
    const http = getAxiosInstance();
    dispatch({
      type: UPDATE_SUBVENTION_REQUEST,
    });
    try {
      const formData = new FormData();
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      formData.append("files.featuredImage", file);
      formData.append(
        "data",
        JSON.stringify({
          ...subventionData,
          published_at: subventionData.published_at
            ? subventionData.published_at
            : null,
        })
      );
      const data = await http.put(`/subventions/${id}`, subventionData);
      dispatch({
        type: UPDATE_SUBVENTION_SUCCESS,
        payload: {
          subventionData: data,
        },
      });
    } catch (e) {
      dispatch({
        type: UPDATE_SUBVENTION_FAILURE,
      });
    }
  };

export const DELETE_SUBVENTION_REQUEST = 'SUBVENTION@DELETE_SUBVENTION_REQUEST';
export const DELETE_SUBVENTION_SUCCESS = 'SUBVENTION@DELETE_SUBVENTION_SUCCESS';
export const DELETE_SUBVENTION_FAILURE = 'SUBVENTION@DELETE_SUBVENTION_FAILURE';

export const deleteSubvention = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_SUBVENTION_REQUEST,
  });
  try{
    const data = await http.delete(`/subventions/${id}`);
    dispatch({
      type: DELETE_SUBVENTION_SUCCESS,
      payload: {
        subventionId:id,
      }
    });
    return data;
  } catch(e){
    dispatch({
      type: DELETE_SUBVENTION_FAILURE,
    });
  };
};
