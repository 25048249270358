import { Fragment, useState } from "react";
import { Login } from "react-facebook";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import GoogleLogin from "react-google-login";
import AppleSignin from "react-apple-signin-auth";
import { Dialog, Transition } from "@headlessui/react";
import GoogleLogo from "../../img/google_logo.svg";
import FacebookLogo from "../../img/fb_logo.svg";
import EmailLogo from "../../img/email_login.svg";
import AppleLogo from "../../img/apple_logo.svg";
import WithConnectedModal from "../../utils/withConnectedModal";
import { getProfile, loginToken } from "../../middleware/actions/user";
import { openModal } from "../../middleware/actions/modal";
import { EMAIL_LOGIN_MODAL_ID, LOGIN_MODAL_ID } from "../../consts/modalIds";
import { useIntl } from "react-intl";



const LoginModal = ({
  isOpen = false,
  closeModal,
  openModal,
  loginToken,
  getProfile,
}) => {
  const history = useHistory();
  const [error, setError] = useState("");

  const handleFbResponse = async (data) => {
    const user = await loginToken("facebook", {
      access_token: data.tokenDetail.accessToken,
    });
    closeModal();
    await getProfile();
    if (!user.user.profileComplete) {
      history.push("/completeProfile");
    } else {
      history.push("/reports");
    }
  };

  const handleGoogleResponse = async (data) => {
    const user = await loginToken("google", {
      access_token: data.accessToken,
    });
    closeModal();
    await getProfile();
    if (!user.user.profileComplete) {
      history.push("/completeProfile");
    } else {
      history.push("/reports");
    }
  };

  const handleGoogleFailure = async (error) => {
    setError(error);
  };

  const emailLoginClicked = () => {
    closeModal();
    openModal(EMAIL_LOGIN_MODAL_ID);
  };

  const { formatMessage } = useIntl();
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={isOpen}
        onClose={closeModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-modal bg-bottom bg-no-repeat bg-cover rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="px-5 sm:px-0">
                <button
                  type="button"
                  className="focus:outline-none focus:ring-0 absolute right-0 text-white p-3"
                  onClick={closeModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className="flex flex-col items-center justify-center sm:py-24 py-10 space-y-4 px-8 sm:px-0">
                <GoogleLogin
                  clientId={process.env.RAZZLE_GOOGLE_ID}
                  cookiePolicy={"single_host_origin"}
                  onSuccess={handleGoogleResponse}
                  onFailure={handleGoogleFailure}
                  render={({ onClick, disabled }) => (
                    <>
                      <button
                        onClick={onClick}
                        disabled={disabled}
                        className="inline-flex w-full sm:w-285 items-center justify-start border border-transparent text-sm md:text-lg font-semibold rounded-2xl text-lightGray5 bg-gray-100 hover:bg-blue hover:text-white px-3.5 py-2 shadow-md focus:outline-none"
                      >
                        <img
                          src={GoogleLogo}
                          alt="Sign in with google"
                          width="19"
                          height="auto"
                          className="mr-3 md:w-5"
                        />
                        {formatMessage({ id: "loginGoogle" })}
                      </button>
                      <div className="text-center text-red">
                        {error && error.response?.data[0].messages[0].id}
                      </div>
                    </>
                  )}
                />
                <Login scope="email" onCompleted={handleFbResponse}>
                  {({ loading, handleClick, error, data }) => (
                    <>
                      <button
                        className="inline-flex w-full sm:w-285 items-center justify-start border border-transparent text-sm md:text-lg font-semibold rounded-2xl text-lightGray5 bg-gray-100 hover:bg-blue hover:text-white px-3.5 py-2 shadow-md"
                        onClick={handleClick}
                        disabled={loading}
                      >
                        <img
                          src={FacebookLogo}
                          alt="Sign in with facebook"
                          width="11"
                          height="auto"
                          className="mr-3 md:w-3"
                        />{" "}
                        {formatMessage({ id: "loginFacebook" })}
                      </button>
                      {error && (
                        <div className="text-center text-red">
                          {error.response.data[0].messages[0].id}
                        </div>
                      )}
                    </>
                  )}
                </Login>

                {/* <AppleSignin
                  authOptions={{
                    clientId: "com.moveo.mzaednica.server",
                    scope: "email name",
                    redirectURI:
                      typeof window === "undefined"
                        ? ""
                        : `${window.location.protocol}//${window.location.hostname}`,
                    usePopup: true,
                  }} // REQUIRED
                  noDefaultStyle={false}
                  buttonExtraChildren="Continue with Apple"
                  onSuccess={(response) => console.log(response)} // default = undefined
                  onError={(error) => console.error(error)} // default = undefined
                  skipScript={false} // default = undefined
                  render={(props) => (
                    <button
                      className="inline-flex w-full sm:w-285 items-center justify-start border border-transparent text-sm md:text-lg font-semibold rounded-2xl text-lightGray5 bg-gray-100 hover:bg-blue hover:text-white px-3.5 py-2 shadow-md"
                      {...props}
                    >
                      <img
                        src={AppleLogo}
                        alt="Sign in with Apple"
                        width="17"
                        height="auto"
                        className="mr-3 md:w-5"
                      />{" "}
                      Најава со Apple ID
                    </button>
                  )}
                /> */}
                {/* <a
                        href="#"
                        className="w-auto flex items-center justify-center border border-transparent text-lg font-semibold rounded-2xl text-lightGray5 bg-gray-100 hover:bg-blue hover:text-white py-3 px-11 shadow-md"
                      >
                        <img
                          src={GoogleLogo}
                          alt="Sign in with google"
                          width="24"
                          height="auto"
                          className="mr-3"
                        />{" "}
                        Најава со Apple ID
                      </a> */}
                <button
                  type="button"
                  className="inline-flex w-full sm:w-285 items-center justify-start border border-transparent text-sm md:text-lg font-semibold rounded-2xl text-lightGray5 bg-gray-100 hover:bg-blue hover:text-white px-3.5 py-2 shadow-md focus:outline-none"
                  onClick={emailLoginClicked}
                >
                  <img
                    src={EmailLogo}
                    alt="Sign in with email"
                    width="20"
                    height="auto"
                    className="mr-3 md:w-6"
                  />{" "}
                  {formatMessage({ id: "loginEmail" })}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapDispatchToProps = {
  loginToken,
  openModal,
  getProfile,
};

export default WithConnectedModal(
  LOGIN_MODAL_ID,
  connect(null, mapDispatchToProps)(LoginModal)
);
