import { useIntl } from "react-intl";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import SVG, { Props as SVGProps } from "react-inlinesvg";
import { getMunicipalities } from "../middleware/selectors/municipality";
import { getInterests } from "../middleware/selectors/interest";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";
import ProfileMenuIcon from "../img/profile_menu_gray.svg";
import EditIcon from "../img/edit.svg";
import graySVG from "../utils/graySVG";
import { updateMe, getProfile } from "../middleware/actions/user";
import { getUserDetails } from "../middleware/selectors/user";
import { useHistory } from "react-router";
import { uploadFile } from "../middleware/actions/upload";
import withAuthentication from "../utils/withAuthentication";

const UserSchema = yup.object().shape({
  firstName: yup.string().required("Ова поле е задолжителнo"),
  familyName: yup.string().required("Ова поле е задолжителнo"),
});

const accountTypes = [
  {
    label: "Поединец",
    value: "individual",
  },
  { label: "НВО", value: "ngo" },
  { label: "Образовна институција", value: "education" },
  { label: "Компанија", value: "company" },
  { label: "Медиум", value: "media" },
];

const genders = [
  {
    label: "М",
    value: "Male",
  },
  { label: "Ж", value: "Female" },
];

const CompleteProfile = ({
  municipalities,
  interests,
  userDetails,
  updateMe,
  getProfile,
}) => {
  const { isFetching, isFetched, listData } = municipalities;
  const history = useHistory();

  const [initialValues, setInitialValues] = useState({
    municipality: 0,
    accountType: "individual",
    birth: "1970-01-01",
    firstName: "",
    familyName: "",
    gender: "Male",
    card: "false",
    cardNumber: "",
    interests: [],
  });

  const [to_text, toggleType] = useState(true);

  const toggleInputType = () => {
    toggleType(!to_text);
  };

  useEffect(() => {
    if (isFetched && !initialValues.municipality) {
      setInitialValues({
        ...initialValues,
        municipality: listData[0].id,
      });
    }
  }, [isFetched, listData]);

  useEffect(() => {
    if (isFetched) {
      setInitialValues({
        ...initialValues,
        municipality: listData[0].id,
      });
    }
  }, [isFetched, listData]);

  useEffect(() => {
    if (!userDetails.isFetched && !userDetails.isFetching) {
      history.push("/");
    } else if (userDetails.userData) {
      const userData = userDetails.userData;
      const newInitialVals = {
        ...initialValues,
        firstName: userData.firstName || "",
        familyName: userData.familyName || "",
        accountType: userData.accountType || "",
        birth: userData.birth || "1970-01-01",
        gender: userData.gender || "Male",
        card: userData.card || "false",
        cardNumber: userData.cardNumber || "",
        interests: userData.interests
          ? userData.interests.map((x) => x.id)
          : [],
        municipality: userData.municipality
          ? userData.municipality.id
          : initialValues.municipality,
      };
      setInitialValues(newInitialVals);
    }
  }, [userDetails]);

  const [photo, setPhoto] = useState(null);
  const [photoDataURL, setPhotoDataURL] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const profilePhotoChanged = useCallback(
    async (e) => {
      if (e.target.files.length > 0) {
        setIsUploading(true);
        setPhotoDataURL(URL.createObjectURL(e.target.files[0]));
        const formData = new FormData();
        formData.append("files", e.target.files[0]);
        formData.append("source", "users-permissions");
        formData.append("ref", "user");
        formData.append("refId", userDetails.userData.id);
        formData.append("field", "profilePicture");
        const fileData = await uploadFile(formData);
        setIsUploading(false);
        setPhoto(fileData[0]);
      }
    },
    [userDetails]
  );

  const submitForm = useCallback(
    async (values) => {
      await updateMe({
        ...values,
        profileComplete: true,
      });
      await getProfile();

      history.push("/home");
    },
    [userDetails, photo]
  );

  const { formatMessage } = useIntl();
  return (
    <>
      <Header />
      <section className="bg-lightGray4">
        <Formik
          initialValues={initialValues}
          onSubmit={submitForm}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form className="container w-840 max-w-full pt-20 pb-20 md:pt-103 md:pb-48">
              <label className="block text-center px-4 md:px-0">
                <span className="text-lightGray5 font-semibold text-xl md:text-2xl">
                  {formatMessage({ id: "municipality" })}
                </span>
                <Field
                  as="select"
                  name="municipality"
                  className="block w-376 max-w-full mt-5 mx-auto shadow-lg px-6 py-5 text-sm md:text-xl font-semibold text-lightGray5 rounded-3xl bg-white border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                >
                  {listData.map((municipality) => (
                    <option key={municipality.id} value={municipality.id}>
                      {municipality.name}
                    </option>
                  ))}
                </Field>
              </label>
              <h2 className="text-xl md:text-2xl	font-semibold text-lightGray5 text-center mt-16">
                {formatMessage({ id: "typeOfProfile" })}
              </h2>
              <div className="px-4 md:px-0 mt-10">
                {accountTypes.map((accountType) => (
                  <div
                    key={accountType.value}
                    className="flex items-center border-b border-lightGray3 pb-6 pt-8"
                  >
                    <Field
                      id={accountType.value}
                      name="accountType"
                      type="radio"
                      className="h-5 w-5"
                      value={accountType.value}
                    />
                    <label
                      htmlFor={accountType.value}
                      className="ml-4 md:ml-6 block text-base md:text-xl font-semibold text-darkGray1"
                    >
                      {accountType.label}
                    </label>
                  </div>
                ))}
              </div>
              <h2 className="text-xl md:text-2xl font-semibold text-lightGray5 text-center mt-16">
                {formatMessage({ id: "myProfile" })}
              </h2>
              <div className="flex flex-col justify-center items-center mt-10">
                <img
                  src={photoDataURL ? photoDataURL : ProfileMenuIcon}
                  alt="Profile picture"
                  className="w-28 h-28 bg-gray-300 rounded-full border-2 border-blue object-cover"
                />
                <label
                  htmlFor="profilePic"
                  className="text-blue font-medium text-base md:text-lg mt-4 mb-11"
                >
                  {formatMessage({ id: "attachPhoto" })}
                </label>
                <input
                  id="profilePic"
                  type="file"
                  className="hidden"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={profilePhotoChanged}
                  multiple
                />
              </div>
              <div className="px-4 md:px-0">
                <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                  <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                    {formatMessage({ id: "name" })}
                  </span>
                  <div className="inline-flex items-center justify-between">
                    <Field
                      name="firstName"
                      type="text"
                      className="w-auto text-lg md:text-xl bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3"
                      placeholder="Petar"
                    />
                    <img src={EditIcon} width="18" height="auto" />
                  </div>
                </label>
                <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                  <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                    {formatMessage({ id: "surname" })}
                  </span>
                  <div className="inline-flex items-center justify-between">
                    <Field
                      name="familyName"
                      type="text"
                      className="w-auto text-lg md:text-xl bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3"
                      placeholder="Petkovski"
                    />
                    <img src={EditIcon} width="18" height="auto" />
                  </div>
                </label>
                <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                  <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                    {formatMessage({ id: "age" })}
                  </span>
                  <Field
                    type="date"
                    name="birth"
                    className="w-auto bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3 text-lg md:text-xl"
                    placeholder="23"
                  />
                </label>
                <div className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                  <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                    {formatMessage({ id: "sex" })}
                  </span>
                  <div>
                    {genders.map((gender) => (
                      <label
                        key={gender}
                        htmlFor={gender.value}
                        className="mr-10"
                      >
                        <Field
                          id={gender.value}
                          value={gender.value}
                          name="gender"
                          type="radio"
                          className="h-5 w-5"
                        />
                        <span className="text-lightGray3 ml-1.5 text-lg md:text-xl">
                          {gender.label}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
                <div className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                  <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                    {formatMessage({ id: "muniCard" })}
                  </span>
                  <div>
                    <label htmlFor="yes" className="mr-10">
                      <Field
                        id="yes"
                        name="card"
                        type="radio"
                        value="true"
                        className="h-5 w-5"
                      />
                      <span className="text-lightGray3 ml-1.5">
                        {formatMessage({ id: "yes" })}
                      </span>
                    </label>
                    <label htmlFor="no">
                      <Field
                        id="no"
                        name="card"
                        type="radio"
                        className="h-5 w-5"
                        value="false"
                      />
                      <span className="text-lightGray3 ml-1.5">
                        {formatMessage({ id: "no" })}
                      </span>
                    </label>
                  </div>
                </div>
                {values.card === "true" && (
                  <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                    <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                      {formatMessage({ id: "cardNumber" })}
                    </span>
                    <div className="inline-flex items-center justify-between">
                      <Field
                        name="cardNumber"
                        type="text"
                        className="w-auto bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3 text-lg md:text-xl"
                        placeholder="ID1234"
                      />
                      <img src={EditIcon} width="18" height="auto" />
                    </div>
                  </label>
                )}
              </div>
              <div>
                <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                  <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                    {formatMessage({ id: "newPassword" })}
                  </span>
                  <div className="inline-flex items-center justify-between">
                    <Field
                      name="password"
                      className="w-auto bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3 text-lg md:text-xl"
                      type={to_text ? "password" : "text"}
                    />
                    <div className="inline-flex w-auto justify-end items-center">
                      <button
                        type="button"
                        className="pr-3 text-md font-bold text-blue disabled:opacity-20"
                        onClick={() => toggleInputType()}
                      >
                        {to_text ? "show" : "hide"}
                      </button>
                    </div>
                    <img src={EditIcon} width="18" height="auto" />
                  </div>
                </label>
              </div>
              <h2 className="text-xl md:text-2xl font-semibold text-lightGray5 text-center mt-16">
                {formatMessage({ id: "fieldOfInterest" })}
              </h2>
              <section className="grid grid-cols-2 sm:grid-cols-4 gap-x-8 gap-y-8 md:gap-y-20 mt-14 px-4 md:px-0">
                <FieldArray name="interests">
                  {({
                    move,
                    swap,
                    push,
                    insert,
                    unshift,
                    remove,
                    pop,
                    form,
                  }) => {
                    const interestsVal = form.values.interests;
                    return (
                      <>
                        {interests.isFetched &&
                          interests.listData.map((interest) => {
                            const selectedIdx = interestsVal.indexOf(
                              interest.id
                            );
                            return (
                              <button
                                key={interest.id}
                                className="flex flex-col items-center focus:outline-none "
                                type="button"
                                onClick={() => {
                                  if (selectedIdx >= 0) {
                                    remove(selectedIdx);
                                  } else {
                                    push(interest.id);
                                  }
                                }}
                              >
                                <SVG
                                  key={`${interest.id}_${selectedIdx >= 0}`}
                                  src={`${process.env.RAZZLE_API_URL}/${interest.featuredImage.url}`}
                                  width="120"
                                  height="100"
                                  fill="gray"
                                  preProcessor={
                                    selectedIdx >= 0 ? null : graySVG
                                  }
                                />
                                <p
                                  className={`font-semibold text-base md:text-22 text-center mt-4 ${
                                    selectedIdx >= 0
                                      ? "text-blue"
                                      : "text-lightGray5"
                                  }`}
                                >
                                  {interest.name}
                                </p>
                              </button>
                            );
                          })}
                      </>
                    );
                  }}
                </FieldArray>
              </section>
              <div className="flex justify-end mt-10 md:mt-20 px-4 md:px-0">
                <button
                  type="submit"
                  disabled={isUploading}
                  className="flex justify-center py-1.5 px-10 border border-transparent shadow-sm text-lg font-semibold rounded-xl text-white bg-blue hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue disabled:opacity-20"
                >
                  {formatMessage({ id: "save" })}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </section>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  municipalities: getMunicipalities(state),
  interests: getInterests(state),
  userDetails: getUserDetails(state),
});

const mapDispatchToProps = {
  updateMe,
  getProfile,
};

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(CompleteProfile),
  true,
  false
);
