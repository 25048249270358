import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import dayjs from "dayjs";
import moment from "moment";
import * as Scroll from "react-scroll";

import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";
import EnrolmentCalendar from "../../components/Enrolments/EnrolmentCalendar.js";
import HasEnrolmentRequestBar from "../../atoms/HasEnrolmentRequestBar.js";
import ConfirmedMeeting from "../../atoms/ConfirmedMeeting";
import {
  fetchEnrolmentClaimByEmbg,
  listEnrolmentClaims,
  updateEnrolmentClaim,
  setCancelationFlow,
  setEnrolmentChosenDate,
  setEnrolmentDocTypeSubmition,
} from "../../middleware/actions/enrolmentRequest";
import {
  getEnrolmentRequests,
  getCancelationFlow,
  getEnrolmentRequestMeeting,
} from "../../middleware/selectors/enrolmentRequest";
import withAuthentication from "../../utils/withAuthentication.js";
import parseDate from "../../utils/parseDate";
import { openModal } from "../../middleware/actions/modal";
import ChangeSchoolModal from "../../components/Modals/ChangeSchoolModal.js";
import { CHANGE_SCHOOL_MODAL_ID } from "../../consts/modalIds";
import InfoBarEnrolmentRequest from "../../atoms/InfoBarEnrolmentRequest.js";
import ChooseTimeSlot from "../../atoms/ChooseTimeSlot.js";
import enumerateDaysBetweenDates from "../../utils/enumerateDates.js";
import { fetchEducationalFacility } from "../../middleware/actions/educationalFacility.js";
import { getEducationalFacilityDetails } from "../../middleware/selectors/educationalFacility.js";
import { getUserDetails } from "../../middleware/selectors/user.js";
import ChooseSubmitionType from "../../atoms/ChooseSubmitionType.js";
import SearchExistingRequest from "../../atoms/SearchExistingRequest";

import "./ConfirmEnrolment.css";
import "react-calendar/dist/Calendar.css";

const pupilTypes = ["Реонски обврзници", "По место на живеење", "По барање"];

const ConfirmEnrolment = ({
  user,
  enrolmentClaimList,
  fetchEnrolmentClaimByEmbg,
  fetchEducationalFacility,
  educationalFacility,
  updateEnrolmentClaim,
  listEnrolmentClaims,
  setCancelationFlow,
  cancelationFlow,
  oldSchoolEnrolmentRequest,
  openModal,
  setEnrolmentChosenDate,
  setEnrolmentDocTypeSubmition,
}) => {
  const [currentTab, setCurrentTab] = useState(!cancelationFlow ? 0 : 1);
  const initialValues = {
    embg: "",
  };
  const initialValuesSubmitionType = {
    docsSubmitionType: "",
  };
  const initialValuesReschedule = {
    meetingStartTime: "",
  };
  const initalValuesNewSchoolEnrolment = {
    newSlot: "",
  };
  const [hasSlot, setHasSlot] = useState(false);
  const [requestUpdated, setRequestUpdated] = useState(false);
  const [requestAlreadyUpdated, setRequestAlreadyUpdated] = useState(false);
  const [meeting, setMeeting] = useState(null);
  const [dates, setDates] = useState({});
  const [freeSlots, setFreeSlots] = useState(null);
  const [rescheduleMode, setRescheduleMode] = useState(false);
  const [errMeetingMessage, setErrMeetingMessage] = useState({
    show: false,
    message: "",
  });
  const { id } = useParams();
  const history = useHistory();
  const scroll = Scroll.animateScroll;

  useEffect(() => {
    scroll.scrollToTop();
    listEnrolmentClaims({
      educational_facility: id,
    });
    fetchEducationalFacility(id);
  }, []);

  useEffect(() => {
    // Group the slots in object with properties - dates
    // (exm. {"2022-08-10": { meetingStartTime: ... , meetingEndTime: ...}}).
    let dates = {};
    let list = [];

    if (!educationalFacility.educationalFacilityData) {
      history.push("/services");
      return;
    }
    list = enumerateDaysBetweenDates(
      educationalFacility.educationalFacilityData.enrolmentStartDate,
      educationalFacility.educationalFacilityData.enrolmentEndDate
    );

    list.length &&
      list.forEach((date) => {
        const fDate = moment(date).format("YYYY-MM-DD");
        dates[fDate] = [];
      });

    enrolmentClaimList.listData.forEach((item) => {
      const meetingDate = item.meetingDate;
      dates[meetingDate] = dates[meetingDate] || [];
      dates[meetingDate].push({
        meetingStartTime: item.meetingStartTime,
        meetingEndTime: item.meetingEndTime,
      });
    });
    setDates(dates);
  }, [enrolmentClaimList, educationalFacility]);

  const onSubmitHandler = async (values, { resetForm }) => {
    setHasSlot(false);
    const params = { ...values, id };
    const enrolmentClaim = await fetchEnrolmentClaimByEmbg(params);

    console.log("enriolmentClaime", enrolmentClaim);

    if (
      enrolmentClaim &&
      enrolmentClaim.status != "cancelled" &&
      enrolmentClaim.status != "confirmed" &&
      enrolmentClaim.status != "submitted"
    ) {
      setHasSlot(true);
      setMeeting({ ...enrolmentClaim });
      setErrMeetingMessage({ show: false, message: "" });
      resetForm(initialValues);
      scroll.scrollMore(300);
    } else if (enrolmentClaim && enrolmentClaim.status == "cancelled") {
      setErrMeetingMessage({
        show: true,
        message: "Уписот е претходно откажан.",
      });
    } else if (enrolmentClaim && enrolmentClaim.status == "confirmed") {
      setRequestAlreadyUpdated(true);
      setMeeting({ ...enrolmentClaim });
      setErrMeetingMessage({ show: false, message: "" });
      resetForm(initialValues);
      scroll.scrollMore(300);
    } else if (enrolmentClaim && enrolmentClaim.status == "submitted") {
      setErrMeetingMessage({
        show: true,
        message: "Уписот е претходно потврден.",
      });
    } else {
      setMeeting(null);
      setErrMeetingMessage({ show: true, message: "Не е пронајден запис." });
    }
  };

  const onRescheduleHandler = async ({ newSlot }) => {
    const { meetingStartTime, meetingDate } = JSON.parse(newSlot);
    if (meeting) {
      await updateEnrolmentClaim(meeting.id, {
        status: "confirmed",
        meetingStartTime,
        meetingEndTime: dayjs(meetingStartTime).add(
          educationalFacility.educationalFacilityData.meetingDuration,
          "minutes"
        ),
        meetingDate,
      });

      const newMeeting = { ...meeting };
      newMeeting.meetingStartTime = meetingStartTime;
      newMeeting.meetingEndTime = dayjs(meetingStartTime).add(
        educationalFacility.educationalFacilityData.meetingDuration,
        "minutes"
      );
      newMeeting.meetingDate = parseDate(meetingDate);

      setMeeting(newMeeting);
      setHasSlot(false);
    }
    setEnrolmentChosenDate(meetingStartTime);
    setRequestUpdated(true);
    scroll.scrollMore(200);
  };

  const onConfirmHandler = async () => {
    if (meeting.status != "confirmed") {
      await updateEnrolmentClaim(meeting.id, {
        status: "confirmed",
      });
    }
    setHasSlot(false);
    if (meeting && meeting.status != "confirmed") {
      setRequestUpdated(true);
      setEnrolmentChosenDate(meeting.meetingStartTime);
    } else if (meeting && meeting.status == "confirmed") {
      setRequestAlreadyUpdated(true);
      setEnrolmentChosenDate(meeting.meetingStartTime);
    }
    scroll.scrollMore(200);
  };

  const onRescheduleSlotHandler = () => {
    setHasSlot(false);
    setRescheduleMode(true);
    scroll.scrollToTop();
  };

  const onCancelHandler = () => {
    setCancelationFlow(meeting);
    openModal(CHANGE_SCHOOL_MODAL_ID);
  };

  const onChooseSubmitionType = async ({ docsSubmitionType }) => {
    setEnrolmentDocTypeSubmition(docsSubmitionType);
    meeting && setEnrolmentChosenDate(meeting.meetingStartTime);

    if (
      (meeting || oldSchoolEnrolmentRequest) &&
      docsSubmitionType === "byHand"
    ) {
      const req = meeting ?? oldSchoolEnrolmentRequest;
      await updateEnrolmentClaim(req.id, {
        user: user.userData.id,
      });
      history.push("/profile#myServices");
      return;
    }
    if (meeting || oldSchoolEnrolmentRequest) {
      const claimId = meeting ? meeting.id : oldSchoolEnrolmentRequest.id;
      history.push(`/createSchoolEnrolmentRequest/${id}/${claimId}`);
    } else {
      history.push(`/createSchoolEnrolmentRequest/${id}/new`);
    }
  };

  const onCreateEnrolmentHandler = async (values) => {
    const { newSlot } = values;
    const { meetingStartTime, meetingDate } = JSON.parse(newSlot);

    await updateEnrolmentClaim(oldSchoolEnrolmentRequest.id, {
      educational_facility: id,
      old_educational_facility:
        oldSchoolEnrolmentRequest.educational_facility.name,
      meetingStartTime,
      meetingEndTime: dayjs(meetingStartTime).add(
        educationalFacility.educationalFacilityData.meetingDuration,
        "minutes"
      ),
      meetingDate,
    });

    setEnrolmentChosenDate(meetingStartTime);
    setRequestUpdated(true);
    scroll.scrollMore(200);
  };

  const resetTabsInfo = () => {
    setRescheduleMode(false);
  };

  const onUpdateRequestStatusHandler = async (status) => {
    const req = meeting ?? oldSchoolEnrolmentRequest;
    await updateEnrolmentClaim(req.id, {
      status,
    });
  };

  return (
    <>
      <Header />
      <section className="pt-8 pb-40">
        <InfoBarEnrolmentRequest
          currentTab={currentTab}
          pupilTypes={pupilTypes}
          setCurrentTab={setCurrentTab}
          cancelationFlow={cancelationFlow}
          resetTabsInfo={resetTabsInfo}
          isRegularFlowStarted={!!meeting}
        />
        <div className="container max-w-max gap-30 grid grid-cols-1 px-4 lg:px-0">
          {!cancelationFlow && currentTab === 0 && (
            <>
              <SearchExistingRequest
                initialValues={initialValues}
                onSubmitHandler={onSubmitHandler}
              />
              {errMeetingMessage.show && (
                <div className="flex flex-col relative font-semibold bg-red bg-opacity-50 rounded-lg mb-10 flex-wrap p-7 mx-5">
                  {errMeetingMessage.message}
                </div>
              )}
            </>
          )}
          <div className="flex flex-col mb-10 flex-wrap md:flex-nowrap px-5 mt-7">
            <EnrolmentCalendar
              meeting={meeting}
              dates={dates}
              setFreeSlots={setFreeSlots}
              enrolmentClaimList={enrolmentClaimList}
              requestUpdated={requestUpdated}
              cancelationFlow={cancelationFlow}
              oldSchoolEnrolmentRequest={oldSchoolEnrolmentRequest}
              educationalFacility={educationalFacility}
              currentTab={currentTab}
              rescheduleMode={rescheduleMode}
            />
          </div>

          {hasSlot && meeting && (
            <HasEnrolmentRequestBar
              meetingInfo={meeting}
              onConfirm={onConfirmHandler}
              onReschedule={onRescheduleSlotHandler}
              onCancel={onCancelHandler}
            />
          )}
          {!requestUpdated && !cancelationFlow && freeSlots && (
            <ChooseTimeSlot
              initalValues={initialValuesReschedule}
              onCreateEnrolmentHandler={onRescheduleHandler}
              freeSlots={freeSlots}
              duration={
                educationalFacility.educationalFacilityData.meetingDuration
              }
            />
          )}
          {cancelationFlow && freeSlots && (
            <ChooseTimeSlot
              initalValues={initalValuesNewSchoolEnrolment}
              onCreateEnrolmentHandler={onCreateEnrolmentHandler}
              freeSlots={freeSlots}
              duration={
                educationalFacility.educationalFacilityData.meetingDuration
              }
            />
          )}
          {requestUpdated && (
            <>
              {meeting && <ConfirmedMeeting meetingInfo={meeting} />}
              <ChooseSubmitionType
                initialValuesSubmitionType={initialValuesSubmitionType}
                onChooseSubmitionType={onChooseSubmitionType}
              />
            </>
          )}
          {requestAlreadyUpdated && (
            <>
              {meeting && <ConfirmedMeeting meetingInfo={meeting} />}
              <ChooseSubmitionType
                initialValuesSubmitionType={initialValuesSubmitionType}
                onChooseSubmitionType={onChooseSubmitionType}
              />
            </>
          )}
        </div>
      </section>
      <Footer />
      <ChangeSchoolModal onUpdateRequestStatus={onUpdateRequestStatusHandler} />
    </>
  );
};

const mapStateToProps = (state) => ({
  enrolmentClaimList: getEnrolmentRequests(state),
  cancelationFlow: getCancelationFlow(state),
  oldSchoolEnrolmentRequest: getEnrolmentRequestMeeting(state),
  educationalFacility: getEducationalFacilityDetails(state),
  user: getUserDetails(state),
});

const mapDispatchToProps = {
  fetchEnrolmentClaimByEmbg,
  fetchEducationalFacility,
  updateEnrolmentClaim,
  listEnrolmentClaims,
  setCancelationFlow,
  openModal,
  setEnrolmentDocTypeSubmition,
  setEnrolmentChosenDate,
};

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmEnrolment)
);
