import { useIntl } from "react-intl";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Footer from "../components/Footer.js";
import ProfileMenu from "../components/ProfileMenu.js";
import ReportCard from "../components/Reports/ReportCard.js";
import MyRequestCard from "../components/Subventions/MyRequestCard";
import MyEnrolmentRequestCard from "../components/Enrolments/MyEnrolmentRequestCard";
import { getUserDetails } from "../middleware/selectors/user";
import {
  getIssues,
  getSupportedIssues,
  getSupports,
} from "../middleware/selectors/issue.js";
import {
  createSupport,
  listIssues,
  listSupportedIssues,
  removeSupport,
} from "../middleware/actions/issue.js";
import { listSubventionClaims } from "../middleware/actions/subventionRequest";
import { listEnrolmentClaims } from "../middleware/actions/enrolmentRequest";
import { getSubventionRequests } from "../middleware/selectors/subventionRequest.js";
import { getEnrolmentRequests } from "../middleware/selectors/enrolmentRequest.js";
import withAuthentication from "../utils/withAuthentication.js";

const Profile = ({
  listIssues,
  issues,
  supportedIssues,
  subventionRequestsList,
  enrolmentRequestsList,
  userDetails,
  listSubventionClaims,
  listEnrolmentClaims,
  listSupportedIssues,
  supports,
  createSupport,
  removeSupport,
  language,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(
    location.hash ? location.hash.slice(1) : "reports"
  );

  useEffect(() => {
    if (userDetails.isFetched) {
      listSubventionClaims({
        "user.id": userDetails.userData.id,
      });
      listSupportedIssues(userDetails.userData.id);
      listIssues({
        "reporter.id": userDetails.userData.id,
      });
      listEnrolmentClaims({
        user: userDetails.userData.id,
      });
    }
  }, [userDetails]);

  const setTab = (tab) => {
    history.push(`#${tab}`);
    setCurrentTab(tab);
  };

  const { formatMessage } = useIntl();
  return (
    <>
      <ProfileMenu userDetails={userDetails} />
      <section className="container">
        {/* tags */}
        <div className="flex items-center justify-center space-x-5 flex-wrap mt-10">
          <button
            className={`w-auto inline-flex items-center justify-center border-2 border-white text-base md:text-lg font-semibold rounded-full hover:bg-opacity-80 py-2 px-7 mb-4 focus:outline-none ${
              currentTab === "reports"
                ? "text-white bg-blue"
                : "text-lightGray2 bg-lightGray1 focus:outline-none"
            }`}
            onClick={() => setTab("reports")}
          >
            {formatMessage({ id: "myReports" })}
          </button>
          <button
            className={`w-auto inline-flex items-center justify-center border-2 border-white text-base md:text-lg font-semibold rounded-full hover:bg-opacity-80 py-2 px-7 mb-4 focus:outline-none ${
              currentTab === "supported"
                ? "text-white bg-blue focus:outline-none"
                : "text-lightGray2 bg-lightGray1 focus:outline-none"
            }`}
            onClick={() => setTab("supported")}
          >
            {formatMessage({ id: "supported" })}
          </button>
          <button
            className={`w-auto inline-flex items-center justify-center border-2 border-white text-base md:text-lg font-semibold rounded-full hover:bg-opacity-80 py-2 px-7 mb-4 focus:outline-none ${
              currentTab === "myServices"
                ? "text-white bg-blue focus:outline-none"
                : "text-lightGray2 bg-lightGray1 focus:outline-none"
            }`}
            onClick={() => setTab("myServices")}
          >
            {formatMessage({ id: "myServices" })}
          </button>
        </div>
        {/* tags */}
      </section>
      {currentTab === "reports" && (
        <div className="container gap-30 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-5 md:px-0 mt-8 mb-16">
          {!issues.isFetching &&
            issues.listData.map((issue) => (
              <ReportCard
                report={issue}
                supported={supports.supportedMap[issue.id]}
                createSupport={createSupport}
                removeSupport={removeSupport}
                language={language}
              />
            ))}
        </div>
      )}
      {currentTab === "supported" && (
        <div className="container gap-30 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-5 md:px-0 mt-8 mb-16">
          {!supportedIssues.isFetching &&
            supportedIssues.listData.map((issue) => (
              <ReportCard
                report={issue}
                supported={supports.supportedMap[issue.id]}
                createSupport={createSupport}
                removeSupport={removeSupport}
                languuage={language}
              />
            ))}
        </div>
      )}
      {currentTab === "myServices" && (
        <div className="container gap-30 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-5 md:px-0 mt-8 mb-16">
          {!subventionRequestsList.isFetching &&
            subventionRequestsList.listData.map((subventionRequest) => (
              <MyRequestCard request={subventionRequest} language={language} />
            ))}
          {!enrolmentRequestsList.isFetching &&
            enrolmentRequestsList.listData.map((enrolmentRequest) => (
              <MyEnrolmentRequestCard
                request={enrolmentRequest}
                language={language}
              />
            ))}
        </div>
      )}
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  userDetails: getUserDetails(state),
  issues: getIssues(state),
  supportedIssues: getSupportedIssues(state),
  subventionRequestsList: getSubventionRequests(state),
  enrolmentRequestsList: getEnrolmentRequests(state),
  supports: getSupports(state),
});

const mapDispatchToProps = {
  listIssues,
  listSubventionClaims,
  listSupportedIssues,
  listEnrolmentClaims,
  createSupport,
  removeSupport,
};

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
