import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";
import AdvisorCard from "../../components/Advisor/AdvisorCard";
import { listUsers } from "../../middleware/actions/user";
import { getUsers } from "../../middleware/selectors/user";
import withAuthentication from "../../utils/withAuthentication.js";

const Advisors = ({ listUsers, userList }) => {
  const { isFetching, isFetched, listData, total } = userList;
  const [currentTab, setCurrentTab] = useState(0);

  const {
    listData: userListData,
    isFetching: userListFetching,
    isFetched: userListFetched,
  } = userList;

  useEffect(() => {
    listUsers({
      "role.type": "municipal_advisor",
      _sort: "updated_at:DESC",
    });
  }, []);

  return (
    <>
      <Header />
      <section className="pt-8 pb-40">
        <div className="container flex flex-col justify-center gap-30 px-4 lg:px-0">
          {userList.listData.map((item) => {
            return <AdvisorCard advisor={item} />;
          })}
        </div>
      </section>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  userList: getUsers(state),
});

const mapDispatchToProps = {
  listUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Advisors);
