import { useIntl } from "react-intl";

import React, { useEffect, useCallback } from "react";
import { Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

import Header from "../../components/Header";
import { fetchEnrolmentClaim } from "../../middleware/actions/enrolmentRequest";
import { getEnrolmentRequestDetails } from "../../middleware/selectors/enrolmentRequest";
import { getUserDetails } from "../../middleware/selectors/user";
import { intl } from "../../utils/translate";
import {
  listEnrolmentRequestComments,
  createEnrolmentRequestComment,
} from "../../middleware/actions/enrolmentRequestComment";
import { getEnrolmentRequestComments } from "../../middleware/selectors/enrolmentRequestComment";
import Footer from "../../components/Footer";
import ProfileIcon from "../../img/profile_menu_gray.svg";
import CameraGray from "../../img/camera_gray.svg";
import CommentMediaField from "../../components/Inputs/CommentMediaField";
import findImageWithQuality from "../../utils/findImageWithQuality";
import ProcessWhite from "../../img/process_white.svg";

const initialValues = {
  content: "",
  file: null,
};

const EnrolmentRequestDetails = ({
  fetchEnrolmentClaim,
  enrolmentRequestDetails,
  listEnrolmentRequestComments,
  createEnrolmentRequestComment,
  comments,
  userDetails,
}) => {
  const { id } = useParams();
  const request = enrolmentRequestDetails.enrolmentRequestData;

  useEffect(() => {
    fetchEnrolmentClaim(id);
    listEnrolmentRequestComments({ "enrolment_request.id": id });
  }, []);

  const submitComment = useCallback(
    async (values, { resetForm }) => {
      if (!userDetails.userData) {
        openModal(LOGIN_MODAL_ID);
      } else if (!userDetails.userData.profileComplete) {
        history.push("completeProfile");
      } else {
        await createEnrolmentRequestComment(values.file, {
          content: values.content,
          enrolment_request: id,
        });
        resetForm();
      }
    },
    [userDetails]
  );

  const { formatMessage } = useIntl();
  return (
    <>
      <Header />
      <div className="container max-w-4xl mx-auto mb-4 font-montserratRegular">
        <div className="text-2xl text-center mt-10 uppercase">
          {formatMessage({ id: "detailsRequestEnrollment" })}
        </div>
        <div className="shadow rounded-lg">
          <table className="mt-10 min-w-full leading-normal">
            <thead></thead>
            <tbody>
              {request && (
                <>
                  <tr>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      {formatMessage({ id: "enrolment" })}
                    </td>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      {request.educational_facility.name}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      {formatMessage({ id: "status" })}
                    </td>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      {formatMessage({ id: request.status.toUpperCase() })}
                    </td>
                  </tr>
                  {request.fields.map((field) => {
                    let component = "";
                    switch (field.__component) {
                      case "fields.text-field":
                        component = field.value;
                        break;
                      case "fields.dropdown-field":
                        component = field.value;
                        break;
                      case "fields.date-field":
                        component = field.value;
                        break;
                      case "fields.rich-text-field":
                        component = field.value;
                        break;
                      case "fields.multiple-media-field":
                        component =
                          field.value != null
                            ? field.value.map((val) => (
                                <>
                                  {val.mime.startsWith("image/") ? (
                                    <a
                                      href={`${process.env.RAZZLE_API_URL}${val.url}`}
                                    >
                                      <img
                                        className="w-20 h-20"
                                        src={`${process.env.RAZZLE_API_URL}${val.url}`}
                                      />
                                    </a>
                                  ) : (
                                    <>
                                      <a
                                        className="text-blue"
                                        href={`${process.env.RAZZLE_API_URL}${val.url}`}
                                      >{`${val.name}`}</a>
                                      <a
                                        href={`${process.env.RAZZLE_API_URL}${val.url}`}
                                        download
                                        target="_blank"
                                        className="text-blue ml-10"
                                      >
                                        {formatMessage({ id: "downloadDoc" })}
                                      </a>
                                    </>
                                  )}
                                  <br />
                                </>
                              ))
                            : "";
                        break;
                    }
                    return (
                      <tr>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          {intl(field.fieldLabel)}
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          {component}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {formatMessage({ id: "timeEditRequest" })}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {moment(request.updated_at).format("DD.MM.YYYY HH:mm a")}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <section className="px-5 md:px-0 pt-7">
          {/* Step 1 */}
          <div className="flex h-full">
            <div className="flex flex-col">
              <div className="w-94 h-auto inline-flex items-center justify-center">
                <figure className="bg-avatarIcon rounded-full w-16 h-16 flex justify-center items-center flex-shrink-0">
                  <img
                    src={
                      request && request.user && request.user.profilePicture
                        ? `${process.env.RAZZLE_API_URL}${findImageWithQuality(
                            request.user.profilePicture
                          )}`
                        : ProfileIcon
                    }
                    alt="Problem icon"
                    className="object-cover w-16 h-16 p-1 rounded-full"
                  />
                </figure>
              </div>
              {comments.isFetched && !!comments.listData.length && (
                <div className="w-94 flex h-full min-h-40 justify-center">
                  <div className="bg-blue w-2.5"></div>
                </div>
              )}
            </div>
            <div className="ml-5 md:ml-10">
              <h6 className="text-base font-semibold text-lightGray3">
                {formatMessage({ id: "submittedЕnrolmentBy" })}
              </h6>
              <h4 className="font-semibold text-darkGray1 text-lg">
                {request &&
                  request.user &&
                  `${request.user.firstName} ${request.user.familyName}`}
              </h4>
              <h6 className="text-base font-semibold text-lightGray3">
                {request && moment(request.created_at).format("DD.MM.YYYY")}
              </h6>
            </div>
          </div>

          {!comments.isFetching &&
            comments.isFetched &&
            comments.listData.map((comment, idx) => {
              const isLast = idx === comments.listData.length - 1;
              let statusText = "";
              let statusIcon = ProcessWhite;
              switch (comment.newStatus) {
                case "in_progress":
                  statusText = formatMessage({ id: "inProcess" });
                  break;
                case "reported":
                  statusText = formatMessage({ id: "submitted" });
                  break;
                case "closed":
                  statusText = formatMessage({ id: "decided" });
                  statusIcon = FinishedWhite;
                  break;
              }
              return (
                <div className="flex h-full">
                  <div className="flex flex-col">
                    <div className="w-94 h-auto inline-flex items-center justify-center">
                      {comment && (
                        <figure className="bg-avatarIcon rounded-full w-16 h-16 flex justify-center items-center flex-shrink-0">
                          <img
                            src={
                              comment.user && comment.user.profilePicture
                                ? `${
                                    process.env.RAZZLE_API_URL
                                  }${findImageWithQuality(
                                    comment.user.profilePicture
                                  )}`
                                : ProfileIcon
                            }
                            alt="Problem icon"
                            className="object-cover w-16 h-16 p-1 rounded-full"
                          />
                        </figure>
                      )}
                      {comment.commentType === "status_change" && (
                        <figure className="bg-blue rounded-full w-68 h-68 inline-flex justify-center items-center flex-shrink-0">
                          <img
                            src={statusIcon}
                            alt="Problem icon"
                            width="46"
                            height="auto"
                          />
                        </figure>
                      )}
                    </div>
                    {!isLast && (
                      <div className="w-94 flex h-full min-h-40 justify-center">
                        <div className="bg-blue w-2.5"></div>
                      </div>
                    )}
                  </div>
                  <div className="ml-5 md:ml-10">
                    <h4 className="font-semibold text-darkGray1 text-lg">
                      {comment.user.firstName} {comment.user.familyName}
                    </h4>
                    <h6 className="text-base font-semibold text-lightGray3">
                      {moment(comment.created_at).format("DD.MM.YYYY")}
                    </h6>
                    <p className="text-base font-semibold text-lightGray3 my-3">
                      {comment.content}
                    </p>
                    {comment.commentType === "comment" &&
                      comment.files.length != 0 && (
                        <>
                          <div className="flex flex-row justify-start items-start mt-5 mb-5 px-4 md:px-0">
                            {comment.files.map((file) => {
                              return (
                                <img
                                  src={`${
                                    process.env.RAZZLE_API_URL
                                  }/${findImageWithQuality(file, "large")}`}
                                  alt="Logo"
                                  className="md:w-64 md:h-64 mr-3"
                                />
                              );
                            })}
                          </div>
                        </>
                      )}
                  </div>
                </div>
              );
            })}

          <Formik initialValues={initialValues} onSubmit={submitComment}>
            {({ isSubmitting }) => (
              <Form className="flex flex-col mb-10 flex-wrap md:flex-nowrap px-5 mt-7">
                <Field name="file">
                  {({ field, form: { setFieldValue } }) => (
                    <CommentMediaField
                      field={field}
                      setFieldValue={setFieldValue}
                    />
                  )}
                </Field>
                <div className="flex flex-row">
                  <label
                    htmlFor="file_file"
                    className="flex flex-col justify-center"
                  >
                    <img
                      src={CameraGray}
                      alt="Camera icon"
                      width="54"
                      height="auto"
                    />
                  </label>
                  <label className="grid grid-cols-2 bg-lightGray4 rounded-full py-3.5 px-4 w-full md:ml-3 mt-4 md:mt-0">
                    <Field
                      name="content"
                      type="text"
                      className="w-auto text-lg md:text-xl font-semibold bg-transparent border-transparent focus:border-transparent focus:bg-transparent focus:ring-0 pl-0 md:pl-3"
                      placeholder={formatMessage({ id: "enterComment" })}
                    />
                    <div className="flex w-auto justify-end items-center">
                      <button
                        type="submit"
                        className="pr-3 text-md font-bold text-blue disabled:opacity-20"
                        disabled={isSubmitting}
                      >
                        {formatMessage({ id: "send" })}
                      </button>
                    </div>
                  </label>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  enrolmentRequestDetails: getEnrolmentRequestDetails(state),
  userDetails: getUserDetails(state),
  comments: getEnrolmentRequestComments(state),
});

const mapDispatchToProps = {
  fetchEnrolmentClaim,
  listEnrolmentRequestComments,
  createEnrolmentRequestComment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnrolmentRequestDetails);
