import React from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useIntl } from "react-intl";

import Header from "../components/Header";

const CancelledEnrolment = () => {
  const { id } = useParams;
  const history = useHistory();
  const { formatMessage } = useIntl();

  return (
    <>
      <Header />
      <div className="mt-20">
        <div className="uppercase text-center text-2xl">
          {id == 2
            ? formatMessage({ id: "cancellationDueToNewMunicipality" })
            : formatMessage({ id: "cancellationDueToMovingOut" })}
        </div>
      </div>
      <div className="w-1/4 mt-16 mb-20 block mx-auto m-0 text-center">
        <Link to="/">
          <button
            type="button"
            className="py-2 px-4 flex justify-center items-center bg-blue text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
          >
            {formatMessage({ id: "404back" })}
          </button>
        </Link>
      </div>
    </>
  );
};

export default CancelledEnrolment;
