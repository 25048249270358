import { useIntl } from "react-intl";

import React, { useCallback, useEffect, useState, useRef } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import * as Scroll from "react-scroll";
import dayjs from "dayjs";

import {
  fetchEnrolmentClaim,
  createEnrolmentClaim,
  updateEnrolmentClaim,
} from "../../middleware/actions/enrolmentRequest";
import { fetchEnrolment } from "../../middleware/actions/enrolment";
import { getEnrolmentDetails } from "../../middleware/selectors/enrolment.js";
import { getEnrolmentRequestDetails } from "../../middleware/selectors/enrolmentRequest.js";
import { embgRegex } from "../../middleware/utils/fieldValidators";
import { uploadFile } from "../../middleware/actions/upload";
import { getUserDetails } from "../../middleware/selectors/user";
import { intl } from "../../utils/translate";
import FieldGroup from "../FieldGroup";
import EnrolmentDocs from "../Enrolments/EnrolmentDocs";
import parseDate from "../../utils/parseDate";

const initValues = {
  familyDoctorProof: [],
  healthCardCopy: [],
  birthCertificate: [],
  employmentCertificate: [],
};

const CreateEnrolmentRequest = ({
  id,
  claimId,
  history,
  fetchEnrolment,
  enrolmentDetails,
  enrolmentClaimDetails,
  updateEnrolmentClaim,
  createEnrolmentClaim,
  fetchEnrolmentClaim,
  userDetails,
  chosenDate,
  submitionType,
}) => {
  console.log("chosenDtae", chosenDate);
  const { enrolmentData } = enrolmentDetails;
  const [initialValues, setInitialValues] = useState(initValues);
  const [validationSchema, setValidationSchema] = useState(yup.object());
  const scroll = Scroll.animateScroll;
  const fileUpload = useRef();
  const { formatMessage } = useIntl();

  const getInitialData = useCallback(async () => {
    const enrolment = await fetchEnrolment(claimId ? 1 : 2);

    if (enrolment) {
      const newInitValues = {};
      const objectShape = {};
      enrolment.fields.forEach((field) => {
        newInitValues[field.fieldName] = null;
        let fieldValidator;
        switch (field.__component) {
          case "fields.text-field":
            fieldValidator = yup.string().ensure();
            if (field.isRequired) {
              fieldValidator = fieldValidator.required(
                formatMessage({ id: "reqField" })
              );
            }
            switch (field.validation) {
              case "email":
                fieldValidator = fieldValidator.email(
                  formatMessage({ id: "invalidemail" })
                );
                break;
              case "embg":
                fieldValidator = fieldValidator.matches(
                  embgRegex,
                  formatMessage({ id: "invalidEMBG" })
                );
                break;
            }
            break;
          case "fields.media-field":
            if (field.isRequired) {
              fieldValidator = yup
                .mixed()
                .required(formatMessage({ id: "reqField" }))
                .typeError(formatMessage({ id: "reqField" }));
            }
            break;
          case "fields.dropdown-field":
            if (field.isRequired) {
              fieldValidator = yup
                .mixed()
                .required(formatMessage({ id: "reqField" }))
                .typeError(formatMessage({ id: "reqField" }));
            }
            break;
        }
        if (fieldValidator) {
          objectShape[field.fieldName] = fieldValidator;
        }
      });
      setValidationSchema(
        yup.object().shape({
          ...objectShape,
          termsAndConditions: yup
            .boolean()
            .oneOf([true], formatMessage({ id: "reqField" })),
        })
      );
      setInitialValues({
        termsAndConditions: false,
        ...initValues,
        ...newInitValues,
      });
    }
  }, []);

  useEffect(() => {
    scroll.scrollToTop();
    claimId && claimId !== "new" && fetchEnrolmentClaim(claimId);
  }, []);

  useEffect(() => {
    getInitialData();
  }, [enrolmentClaimDetails]);

  const submitForm = useCallback(
    async (values) => {
      const dataFields = [];
      for (let i = 0; i < enrolmentData.fields.length; ++i) {
        if (
          enrolmentData.fields[i].__component === "fields.multiple-media-field"
        ) {
          continue;
        }
        const field = enrolmentData.fields[i];
        dataFields.push({
          ...field,
          value: values[field.fieldName],
          id: undefined,
        });
      }

      for (let i = 0; i < enrolmentData.fields.length; ++i) {
        if (
          enrolmentData.fields[i].__component !== "fields.multiple-media-field"
        ) {
          continue;
        }
        for (let fld of values[enrolmentData.fields[i].fieldName]) {
          const formData = new FormData();
          formData.append("files", fld);
          const files = await uploadFile(formData);
          dataFields.push({
            ...enrolmentData.fields[i],
            value: files[0].id,
            id: undefined,
          });
        }
      }

      const params = {
        user: userDetails.userData.id,
        educational_facility: id,
        fields: dataFields,
        status: "submitted",
      };

      if (claimId) {
        // id of the enrolment defines weather this is a school or kindergarden enrolment
        // it is obtained as id parametar from the URL
        if (claimId === "new") {
          const meetingStartTime = chosenDate;
          const meetingEndTime = dayjs(meetingStartTime).add(
            enrolmentData.educational_facility.meetingDuration,
            "minutes"
          );
          const meetingDate = parseDate(chosenDate);
          await createEnrolmentClaim({
            enrolment: 1,
            meetingDate,
            meetingStartTime,
            meetingEndTime,
            ...params,
          });
        } else {
          await updateEnrolmentClaim(claimId, {
            enrolment: 1,
            meetingStartTime: chosenDate,
            ...params,
          });
        }
      } else {
        await createEnrolmentClaim({
          enrolment: 2,
          ...params,
        });
      }
      history.push("/profile#myServices");
    },
    [enrolmentData]
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form className="container w-840 max-w-full md:pt-103 md:pb-48">
            <h2 className="text-xl md:text-2xl font-semibold text-lightGray5 text-center">
              {intl("enrolmentRequest")}
            </h2>
            {enrolmentData && (
              <>
                <FieldGroup fields={enrolmentData.fields} title="basicInfo" />
                {!(submitionType === "byHand") && (
                  <EnrolmentDocs
                    fields={enrolmentData.fields.filter(
                      (field) =>
                        field.__component === "fields.multiple-media-field"
                    )}
                  />
                )}
              </>
            )}
            <div className="mt-10">
              <label>
                <Field type="checkbox" name="termsAndConditions" />
                <small className="pl-2">
                  {formatMessage({ id: "iAgreeEduInstitution" })}
                </small>
              </label>
              <div className="text-red">
                <ErrorMessage name="termsAndConditions" />
              </div>
            </div>

            <div className="flex justify-end mt-10 md:mt-14 px-4 md:px-0">
              <button
                type="submit"
                className="flex justify-center py-1.5 px-10 border border-transparent shadow-sm text-lg font-semibold rounded-xl text-white bg-blue disabled:opacity-20 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue"
                disabled={isSubmitting}
              >
                {formatMessage({ id: "send" })}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => ({
  enrolmentDetails: getEnrolmentDetails(state),
  enrolmentClaimDetails: getEnrolmentRequestDetails(state),
  userDetails: getUserDetails(state),
});

const mapDispatchToProps = {
  fetchEnrolment,
  fetchEnrolmentClaim,
  updateEnrolmentClaim,
  createEnrolmentClaim,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEnrolmentRequest);
