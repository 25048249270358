import getAxiosInstance from "../config/http";

export const CREATE_EDUCATIONAL_FACILITY_REQUEST =
  "EDUCATIONAL_FACILITY@CREATE_EDUCATIONAL_FACILITY_REQUEST";
export const CREATE_EDUCATIONAL_FACILITY_SUCCESS =
  "EDUCATIONAL_FACILITY@CREATE_EDUCATIONAL_FACILITY_SUCCESS";
export const CREATE_EDUCATIONAL_FACILITY_FAILURE =
  "EDUCATIONAL_FACILITY@CREATE_EDUCATIONAL_FACILITY_FAILURE";

export const createEducationalFacility =
  (educationalFacilitydata) => async (dispatch) => {
    const http = getAxiosInstance();
    dispatch({
      type: CREATE_EDUCATIONAL_FACILITY_REQUEST,
    });
    try {
      const { data } = await http.post(
        "/educational-facilities",
        educationalFacilitydata
      );
      dispatch({
        type: CREATE_EDUCATIONAL_FACILITY_SUCCESS,
        payload: {
          educationalFacilitydata: data,
        },
      });
      return data;
    } catch (e) {
      dispatch({
        type: CREATE_EDUCATIONAL_FACILITY_FAILURE,
      });
      throw e;
    }
  };

export const LIST_EDUCATIONAL_FACILITY_REQUEST =
  "EDUCATIONAL_FACILITY@LIST_EDUCATIONAL_FACILITY_REQUEST";
export const LIST_EDUCATIONAL_FACILITY_SUCCESS =
  "EDUCATIONAL_FACILITY@LIST_EDUCATIONAL_FACILITY_SUCCESS";
export const LIST_EDUCATIONAL_FACILITY_FAILURE =
  "EDUCATIONAL_FACILITY@LIST_EDUCATIONAL_FACILITY_FAILURE";

export const listEducationalFacilities = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_EDUCATIONAL_FACILITY_REQUEST,
  });
  try {
    const educationalFacilityData = await http.get("/educational-facilities", {
      params,
    });
    dispatch({
      type: LIST_EDUCATIONAL_FACILITY_SUCCESS,
      payload: {
        listData: educationalFacilityData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_EDUCATIONAL_FACILITY_FAILURE,
    });
  }
};

export const FETCH_EDUCATIONAL_FACILITY_REQUEST =
  "EDUCATIONAL_FACILITY@FETCH_EDUCATIONAL_FACILITY_REQUEST";
export const FETCH_EDUCATIONAL_FACILITY_SUCCESS =
  "EDUCATIONAL_FACILITY@FETCH_EDUCATIONAL_FACILITY_SUCCESS";
export const FETCH_EDUCATIONAL_FACILITY_FAILURE =
  "EDUCATIONAL_FACILITY@FETCH_EDUCATIONAL_FACILITY_FAILURE";

export const fetchEducationalFacility =
  (educationalFacilityId) => async (dispatch) => {
    const http = getAxiosInstance();
    dispatch({
      type: FETCH_EDUCATIONAL_FACILITY_REQUEST,
    });
    try {
      const educationalFacilityData = await http.get(
        `/educational-facilities/${educationalFacilityId}`
      );
      dispatch({
        type: FETCH_EDUCATIONAL_FACILITY_SUCCESS,
        payload: {
          educationalFacilityData: educationalFacilityData.data,
        },
      });
    } catch (e) {
      dispatch({
        type: FETCH_EDUCATIONAL_FACILITY_FAILURE,
      });
    }
  };

export const UPDATE_EDUCATIONAL_FACILITY_REQUEST =
  "EDUCATIONAL_FACILITY@UPDATE_EDUCATIONAL_FACILITY_REQUEST";
export const UPDATE_EDUCATIONAL_FACILITY_SUCCESS =
  "EDUCATIONAL_FACILITY@UPDATE_EDUCATIONAL_FACILITY_SUCCESS";
export const UPDATE_EDUCATIONAL_FACILITY_FAILURE =
  "EDUCATIONAL_FACILITY@UPDATE_EDUCATIONAL_FACILITY_FAILURE";

export const updateEducationalFacility =
  (id, educationalFacilityData) => async (dispatch) => {
    const http = getAxiosInstance();
    dispatch({
      type: UPDATE_EDUCATIONAL_FACILITY_REQUEST,
    });
    try {
      const { data } = await http.put(
        `/educational-facilities/${id}`,
        educationalFacilityData
      );
      dispatch({
        type: UPDATE_EDUCATIONAL_FACILITY_SUCCESS,
        payload: {
          educationalFacilityData: data,
        },
      });
      return data;
    } catch (e) {
      dispatch({
        type: UPDATE_EDUCATIONAL_FACILITY_FAILURE,
      });
    }
  };

export const DELETE_EDUCATIONAL_FACILITY_REQUEST =
  "EDUCATIONAL_FACILITY@DELETE_EDUCATIONAL_FACILITY_REQUEST";
export const DELETE_EDUCATIONAL_FACILITY_SUCCESS =
  "EDUCATIONAL_FACILITY@DELETE_EDUCATIONAL_FACILITY_SUCCESS";
export const DELETE_EDUCATIONAL_FACILITY_FAILURE =
  "EDUCATIONAL_FACILITY@DELETE_EDUCATIONAL_FACILITY_FAILURE";

export const deleteEducationalFacility = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_EDUCATIONAL_FACILITY_REQUEST,
  });
  try {
    const data = await http.delete(`/educational-facilities/${id}`);
    dispatch({
      type: DELETE_EDUCATIONAL_FACILITY_SUCCESS,
      payload: {
        educationalFacilityId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_EDUCATIONAL_FACILITY_FAILURE,
    });
  }
};

export const COUNT_EDUCATIONAL_FACILITY_REQUEST =
  "EDUCATIONAL_FACILITY@COUNT_EDUCATIONAL_FACILITY_REQUEST";
export const COUNT_EDUCATIONAL_FACILITY_SUCCESS =
  "EDUCATIONAL_FACILITY@COUNT_EDUCATIONAL_FACILITY_SUCCESS";
export const COUNT_EDUCATIONAL_FACILITY_FAILURE =
  "EDUCATIONAL_FACILITY@COUNT_EDUCATIONAL_FACILITY_FAILURE";

export const countEducationalFacilities = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: COUNT_EDUCATIONAL_FACILITY_REQUEST,
  });
  try {
    const data = await http.get("/educational-facilities/count", {
      params,
    });
    dispatch({
      type: COUNT_EDUCATIONAL_FACILITY_SUCCESS,
      payload: {
        total: data.data,
      },
    });
    return data.data;
  } catch (e) {
    dispatch({
      type: COUNT_EDUCATIONAL_FACILITY_FAILURE,
    });
  }
};
