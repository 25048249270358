import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useIntl } from "react-intl";

import WithConnectedModal from "../../utils/withConnectedModal";
import { CHANGE_SCHOOL_MODAL_ID } from "../../consts/modalIds";
import CautionIcon from "../../img/caution.svg";

const cancellationReasons = [
  { id: 1, label: "cancellationSameMunicipality" },
  { id: 2, label: "cancellationDifferentMunicipality" },
  { id: 3, label: "cancellationMovingOut" },
];

const ChangeSchoolModal = ({
  isOpen = false,
  closeModal,
  onUpdateRequestStatus,
}) => {
  const history = useHistory();
  const [cancellationReason, setCancellationReason] = useState();
  const { formatMessage } = useIntl();

  const onConfirm = () => {
    closeModal();
    console.log("cancelationReasonId", cancellationReason);
    if (cancellationReason != 1) {
      // Update status to cancelled.
      onUpdateRequestStatus("cancelled");
      history.push(`/cancelledEnrolment/${cancellationReason}`);
    } else {
      history.push(`/services#enrolments`);
    }
  };

  const onChangeReasonHandler = (e) => {
    console.log("e.target.id", e.target.id);
    setCancellationReason(e.target.id);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={isOpen}
        onClose={closeModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full p-8">
              <div className="inline-flex">
                <img src={CautionIcon} width="60" height="auto" />
                <h4 className="text-xl text-left font-bold my-2 mx-2">
                  {formatMessage({ id: "cancellationReason" })}
                </h4>
              </div>
              <div className="flex flex-col">
                {cancellationReasons.map((reason) => {
                  return (
                    <div className="inline-flex items-center justify-left">
                      <input
                        type="radio"
                        id={reason.id}
                        name="cancellationReason"
                        value={reason.label}
                        onChange={onChangeReasonHandler}
                      />
                      <label for={reason.id} className="ml-2">
                        {formatMessage({ id: reason.label })}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="flex w-auto justify-end items-center mt-4">
                <button
                  type="button"
                  disabled={!cancellationReason}
                  className="inline-flex items-center justify-end border border-transparent text-sm md:text-lg font-semibold rounded-2xl text-white bg-blue hover:text-white px-3.5 py-2 shadow-md focus:outline-none disabled:opacity-25"
                  onClick={onConfirm}
                >
                  {formatMessage({ id: "cancelMeeting" })}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default WithConnectedModal(CHANGE_SCHOOL_MODAL_ID, ChangeSchoolModal);
