import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Layout from "./Layout";
import Homepage from "./pages/Homepage";
import CompleteProfile from "./pages/CompleteProfile";
import Reports from "./pages/Reports/Reports";
import SubventionDetails from "./pages/Subventions/SubventionDetails";
import News from "./pages/News/News";
import NewsDetails from "./pages/News/NewsDetails";
import Notifications from "./pages/Notifications";
import CreateSubventionRequest from "./pages/Subventions/CreateSubventionRequest";
import SubventionRequest from "./pages/Subventions/SubventionRequest";
import CreateReport from "./pages/Reports/CreateReport";
import NotFound from "./pages/404";
import Profile from "./pages/Profile";
import About from "./pages/About";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DataDeletion from "./pages/DataDeletion";
import TermsAndConditions from "./pages/TermsAndConditions";
import Contact from "./pages/Contact";
import ReportDetails from "./pages/Reports/ReportDetails";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import CreateSchoolEnrolmentRequest from "./pages/Enrolments/CreateSchoolEnrolmentRequest";
import EnrolmentRequestDetails from "./pages/Enrolments/EnrolmentRequestDetails";
import EnrolmentDetails from "./pages/Enrolments/EnrolmentDetails";
import ConfirmEnrolment from "./pages/Enrolments/ConfirmEnrolment";
import Advisors from "./pages/Chat/Advisors";
import NewMessage from "./pages/Chat/NewMessage";
import MyServices from "./pages/Services/MyServices.js";
import ChooseMunicipality from "./pages/ChooseMunicipality";
import ChooseLanguage from "./pages/ChooseLanguage";
import CancelledEnrolment from "./pages/CancelledEnrolment";

import "./App.css";

const App = () => {
  let location = useLocation();

  return (
    <Layout>
      <Helmet>
        <title>мЗаедница</title>
        <meta property="fb:app_id" content={process.env.RAZZLE_FACEBOOK_ID} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${process.env.RAZZLE_WEB_URL}${location.pathname}`}
        />
      </Helmet>
      <Switch>
        <Route exact path="/" component={ChooseMunicipality} />
        <Route path="/chooseLanguage" component={ChooseLanguage} />
        <Route path="/home" component={Homepage} />
        <Route path="/completeProfile" component={CompleteProfile} />
        <Route path="/editProfile" component={CompleteProfile} />
        <Route path="/reports" component={Reports} />
        <Route path="/createReport" component={CreateReport} />
        <Route path="/subventionDetails/:id" component={SubventionDetails} />
        <Route path="/news" component={News} />
        <Route path="/newsDetails/:id" component={NewsDetails} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/services" component={MyServices} />
        <Route
          path="/createSubventionRequest/:id"
          component={CreateSubventionRequest}
        />
        <Route path="/subventionRequest/:id" component={SubventionRequest} />
        <Route path="/enrolmentDetails/:id" component={EnrolmentDetails} />
        <Route path="/confirmEnrolment/:id" component={ConfirmEnrolment} />
        <Route
          path="/createSchoolEnrolmentRequest/:id/:claimId"
          component={CreateSchoolEnrolmentRequest}
        />
        <Route
          path="/enrolmentRequestDetails/:id"
          component={EnrolmentRequestDetails}
        />
        <Route path="/advisors" component={Advisors} />
        <Route path="/chat/:id" component={NewMessage} />
        <Route path="/profile" component={Profile} />
        <Route path="/about" component={About} />
        <Route path="/privacyPolicy" component={PrivacyPolicy} />
        <Route path="/dataDeletion" component={DataDeletion} />
        <Route path="/termsAndConditions" component={TermsAndConditions} />
        <Route path="/contact" component={Contact} />
        <Route path="/reportDetails/:id" component={ReportDetails} />
        <Route path="/resetPassword" component={ResetPassword} />
        <Route path="/cancelledEnrolment/:id" component={CancelledEnrolment} />
        <Route path="/*" component={NotFound} />
      </Switch>
    </Layout>
  );
};
export default App;
