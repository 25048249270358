import { Formik, Form, Field } from "formik";

const ChooseSubmitionType = ({
  initialValuesSubmitionType,
  onChooseSubmitionType,
}) => {
  return (
    <>
      <Formik
        initialValues={initialValuesSubmitionType}
        onSubmit={onChooseSubmitionType}
      >
        {({ isSubmitting }) => (
          <Form className="container max-w-full">
            <div className="flex flex-col justify-center mt-6 mb-6">
              <label htmlFor="bySubmition">
                <Field
                  id="bySubmition"
                  value="bySubmition"
                  name="docsSubmitionType"
                  type="radio"
                  className="h-5 w-5 md:ml-3"
                />
                <span className="ml-1.5 mr-10 text-lg md:text-xl">
                  Поднеси барање со документи
                </span>
              </label>
            </div>
            <div className="flex flex-col justify-center mt-6 mb-6">
              <label htmlFor="byHand">
                <Field
                  id="byHand"
                  value="byHand"
                  name="docsSubmitionType"
                  type="radio"
                  className="h-5 w-5 md:ml-3"
                />
                <span className="ml-1.5 mr-10 text-lg md:text-xl">
                  Донеси барање и документи
                </span>
              </label>
            </div>
            <div className="flex justify-start mt-10 md:mt-14 px-4 md:px-0">
              <button
                disabled={isSubmitting}
                type="submit"
                className="flex justify-center py-1.5 px-10 border border-transparent shadow-sm text-md md:text-lg font-semibold rounded-xl text-white bg-blue hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue disabled:opacity-20"
              >
                Продолжи
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChooseSubmitionType;
