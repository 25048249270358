import { combineReducers } from "redux";

import user from "./user";
import post from "./post";
import category from "./category";
import issue from "./issue";
import interest from "./interest";
import modal from "./modal";
import municipality from "./municipality";
import notification from "./notification";
import comment from "./comment";
import subventionRequestComment from "./subventionRequestComment";
import enrolmentRequestComment from "./enrolmentRequestComment";
import subvention from "./subvention";
import subventionRequest from "./subventionRequest";
import enrolment from "./enrolment";
import enrolmentRequest from "./enrolmentRequest";
import educationalFacility from "./educationalFacility";
import language from "./language";
import chatMessage from "./chatMessage";

const rootReducer = combineReducers({
  user,
  post,
  category,
  issue,
  interest,
  municipality,
  notification,
  modal,
  comment,
  subventionRequestComment,
  enrolmentRequestComment,
  subvention,
  subventionRequest,
  enrolment,
  enrolmentRequest,
  educationalFacility,
  language,
  chatMessage,
});

export default rootReducer;
