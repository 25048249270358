import getAxiosInstance from "../config/http";

export const LIST_ISSUE_REQUEST = "ISSUE@LIST_ISSUE_REQUEST";
export const LIST_ISSUE_SUCCESS = "ISSUE@LIST_ISSUE_SUCCESS";
export const LIST_ISSUE_FAILURE = "ISSUE@LIST_ISSUE_FAILURE";

export const listIssues = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_ISSUE_REQUEST,
  });
  try {
    const issueData = await http.get("/reports", { params });
    dispatch({
      type: LIST_ISSUE_SUCCESS,
      payload: {
        listData: issueData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_ISSUE_FAILURE,
    });
  }
};

export const LIST_SUPPORTED_ISSUE_REQUEST =
  "ISSUE@LIST_SUPPORTED_ISSUE_REQUEST";
export const LIST_SUPPORTED_ISSUE_SUCCESS =
  "ISSUE@LIST_SUPPORTED_ISSUE_SUCCESS";
export const LIST_SUPPORTED_ISSUE_FAILURE =
  "ISSUE@LIST_SUPPORTED_ISSUE_FAILURE";
export const LIST_SUPPORTS_SUCCESS = "ISSUE@LIST_SUPPORTS_SUCCESS";

export const listSupportedIssues = (userId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_SUPPORTED_ISSUE_REQUEST,
  });
  try {
    const {data : supports} = await http.get("/report-supports", {
      params: {
        "user.id": userId,
        _limit: 200,
      },
    });
    dispatch({
      type: LIST_SUPPORTS_SUCCESS,
      payload: { supports }
    });
    const supportedIds = supports.map((x) => x.report.id);
    let result = [];
    if (supportedIds.length > 0) {
      const issueData = await http.get("/reports", {
        params: {
          _sort: "created_at:DESC",
          id_in: supportedIds,
        },
      });
      result = issueData.data;
    }
    dispatch({
      type: LIST_SUPPORTED_ISSUE_SUCCESS,
      payload: {
        listData: result,
      },
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: LIST_SUPPORTED_ISSUE_FAILURE,
    });
  }
};

export const CREATE_SUPPORT_REQUEST = "ISSUE@CREATE_SUPPORT_REQUEST";
export const CREATE_SUPPORT_SUCCESS = "ISSUE@CREATE_SUPPORT_SUCCESS";
export const CREATE_SUPPORT_FAILURE = "ISSUE@CREATE_SUPPORT_FAILURE";

export const createSupport = (reportId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_SUPPORT_REQUEST,
  });
  try {
    const { data } = await http.post("/report-supports", {
      report: reportId,
    });
    dispatch({
      type: CREATE_SUPPORT_SUCCESS,
      payload: {
        supportData: data,
      },
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: CREATE_SUPPORT_FAILURE,
    });
  }
};

export const REMOVE_SUPPORT_REQUEST = "ISSUE@REMOVE_SUPPORT_REQUEST";
export const REMOVE_SUPPORT_SUCCESS = "ISSUE@REMOVE_SUPPORT_SUCCESS";
export const REMOVE_SUPPORT_FAILURE = "ISSUE@REMOVE_SUPPORT_FAILURE";

export const removeSupport = (supportId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: REMOVE_SUPPORT_REQUEST,
  });
  try {
    const { data } = await http.delete(`/report-supports/${supportId}`);
    dispatch({
      type: REMOVE_SUPPORT_SUCCESS,
      payload: {
        supportData: data,
      },
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: REMOVE_SUPPORT_FAILURE,
    });
  }
};

export const COUNT_ISSUE_REQUEST = "ISSUE@COUNT_ISSUE_REQUEST";
export const COUNT_ISSUE_SUCCESS = "ISSUE@COUNT_ISSUE_SUCCESS";
export const COUNT_ISSUE_FAILURE = "ISSUE@COUNT_ISSUE_FAILURE";

export const countIssues = (params, countType) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: COUNT_ISSUE_REQUEST,
  });
  try {
    const issueData = await http.get("/reports/count", { params });
    dispatch({
      type: COUNT_ISSUE_SUCCESS,
      payload: {
        total: issueData.data,
        countType
      },
    });
    return issueData.data;
  } catch (e) {
    dispatch({
      type: COUNT_ISSUE_FAILURE,
    });
  }
};

export const FETCH_ISSUE_REQUEST = "ISSUE@FETCH_ISSUE_REQUEST";
export const FETCH_ISSUE_SUCCESS = "ISSUE@FETCH_ISSUE_SUCCESS";
export const FETCH_ISSUE_FAILURE = "ISSUE@FETCH_ISSUE_FAILURE";

export const fetchIssue = (issueId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_ISSUE_REQUEST,
  });
  try {
    const issueData = await http.get(`/reports/${issueId}`);
    dispatch({
      type: FETCH_ISSUE_SUCCESS,
      payload: {
        issueData: issueData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: FETCH_ISSUE_FAILURE,
    });
  }
};

export const CREATE_ISSUE_REQUEST = "ISSUE@UPDATE_ISSUE_REQUEST";
export const CREATE_ISSUE_SUCCESS = "ISSUE@UPDATE_ISSUE_SUCCESS";
export const CREATE_ISSUE_FAILURE = "ISSUE@UPDATE_ISSUE_FAILURE";

export const createIssue = (issueData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_ISSUE_REQUEST,
  });
  try {
    const data = await http.post(`/reports`, issueData);
    dispatch({
      type: CREATE_ISSUE_SUCCESS,
      payload: {
        issueData: data,
      },
    });
    return data.data;
  } catch (e) {
    dispatch({
      type: CREATE_ISSUE_FAILURE,
    });
  }
};

export const UPDATE_ISSUE_REQUEST = "ISSUE@UPDATE_ISSUE_REQUEST";
export const UPDATE_ISSUE_SUCCESS = "ISSUE@UPDATE_ISSUE_SUCCESS";
export const UPDATE_ISSUE_FAILURE = "ISSUE@UPDATE_ISSUE_FAILURE";

export const updateIssue = (id, issueData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_ISSUE_REQUEST,
  });
  try {
    const data = await http.put(`/reports/${id}`, issueData);
    dispatch({
      type: UPDATE_ISSUE_SUCCESS,
      payload: {
        issueData: data,
      },
    });
  } catch (e) {
    dispatch({
      type: UPDATE_ISSUE_FAILURE,
    });
  }
};

export const DELETE_ISSUE_REQUEST = "ISSUE@DELETE_ISSUE_REQUEST";
export const DELETE_ISSUE_SUCCESS = "ISSUE@DELETE_ISSUE_SUCCESS";
export const DELETE_ISSUE_FAILURE = "ISSUE@DELETE_ISSUE_FAILURE";

export const deleteIssue = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_ISSUE_REQUEST,
  });
  try {
    const data = await http.delete(`/reports/${id}`);
    dispatch({
      type: DELETE_ISSUE_SUCCESS,
      payload: {
        issueId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_ISSUE_FAILURE,
    });
  }
};
